export const MAXIFY_TOKEN_COOKIE_NAME = 'MAXIFY_TOKEN_2';
export const SHOPIFY_CART_ID_COOKIE_NAME = 'S_CART_ID';
export const ZENDESK_ID_COOKIE_NAME = 'ZENDESK_ID';
export const SYSTEM_LOCALE_COOKIE_NAME = 'NEXT_LOCALE';
export const SYSTEM_GEOIP_COOKIE_NAME = 'SYSTEM_GEOIP';
export const SYSTEM_AMPLITUDE_COOKIE_NAME = 'SYSTEM_AMP_DEV_ID';
export const SYSTEM_IMPACT_COOKIE_NAME = 'SYSTEM_IMPACT_CLICK_ID';
export const SYSTEM_IMPACT_ID_COOKIE_NAME = 'SYSTEM_IMPACT_ID';
export const SYSTEM_IMPACT_EMAIL_COOKIE_NAME = 'SYSTEM_IMPACT_EMAIL';
export const SYSTEM_IS_PARTNER_COOKIE_NAME = 'IS_PARTNER';
export const COOKIE_CONTROL_COOKIE_NAME = 'CookieControl';
export const USER_EMAIL_COOKIE_NAME = 'USER_EMAIL';
export const ADORIC_POPUP_COOKIE_NAME = 'adoric_popup';

export const SYSTEM_COOKIE_MAX_AGE = 7 * 24 * 60 * 60;
export const SYSTEM_IMPACT_COOKIE_MAX_AGE = 30 * 24 * 60 * 60;

export const LOW_STOCK_THRESHOLD = 10;
export const MAX_ORDER_THRESHOLD = 10;

export const NEWS_LIST_PER_PAGE_COUNT = 10;
export const REVIEWS_LIST_PER_PAGE_COUNT = 16;

export const DEFAULT_DATE_FORMAT = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
};

export const REVALIDATE_NOT_FOUND = 10;
export const REVALIDATE_FALLBACK = 45;
export const REVALIDATE_STANDARD = 600;
export const REVALIDATE_VALUES = {
  notFound: REVALIDATE_NOT_FOUND,
  fallback: REVALIDATE_FALLBACK,
  standard: REVALIDATE_STANDARD,
};

export const ELASTIC_INDEX_TIMEOUT = 6000;

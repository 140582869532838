/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';
import useCartStore, { getSetLastProduct } from '@stores/use-cart-store';
import trackAddToCartAmp from '@utils/amplitude/track/add-to-cart';
import trackAddToCartGa from '@utils/gtm/track/add-to-cart';
import trackAddToCartKlaviyo from '@utils/klaviyo/track/add-to-cart';
import type { CartSource } from '@utils/amplitude/types';
import { useHistory } from '@utils/history';
import useCartAddItem from '../commerce/cart/use-add-item';
import type { HookFetcher } from '../utils/types';
import { CommerceError } from '../utils/errors';
import type { ItemBody, AddItemBody } from '../api/cart';
import useCart, { ShopifyCart } from './use-cart';

const defaultOpts = {
  url: '/api/maxify/cart',
  method: 'POST',
};

export type AddItemInput = ItemBody | ItemBody[];

export const fetcher: HookFetcher<ShopifyCart, AddItemBody> = (
  options,
  { item },
  fetch
) => {
  if (
    !Array.isArray(item) &&
    item.quantity &&
    (!Number.isInteger(item.quantity) || item.quantity! < 1)
  ) {
    throw new CommerceError({
      message: 'The item quantity has to be a valid integer greater than 0',
    });
  }

  if (
    Array.isArray(item) &&
    item.some(
      i => i.quantity && (!Number.isInteger(i.quantity) || i.quantity! < 1)
    )
  ) {
    throw new CommerceError({
      message: 'The item quantity has to be a valid integer greater than 0',
    });
  }

  return fetch({
    ...defaultOpts,
    ...options,
    body: { item },
  });
};

export function extendHook(customFetcher: typeof fetcher) {
  const useAddItem = (source?: CartSource) => {
    const { last: lastPage } = useHistory();
    const setLastProduct = useCartStore(getSetLastProduct);
    const { mutate } = useCart();
    const fn = useCartAddItem(defaultOpts, customFetcher);

    return useCallback(
      async function addItem(input: AddItemInput) {
        const data = await fn({ item: input });
        await mutate(data, false);

        if (Array.isArray(input) && input.length === 1) {
          const id = input[0].shopify_variants_id;
          if (!input[0]?.skipSetLastItem) {
            setLastProduct(id);
          }
        }

        if (Array.isArray(input) && input.length > 1) {
          const id = input[0].shopify_variants_id;
          if (!input[0]?.skipSetLastItem) {
            setLastProduct(id);
          }
        }

        if (
          !Array.isArray(input) &&
          input.shopify_variants_id &&
          !input?.skipSetLastItem
        ) {
          setLastProduct(input.shopify_variants_id);
        }

        trackAddToCartAmp({
          cart: data,
          referrer: lastPage,
          addedIds: Array.isArray(input) ? input : [input],
          source,
        });

        trackAddToCartGa({
          cart: data,
          addedIds: Array.isArray(input) ? input : [input],
          source,
        });

        trackAddToCartKlaviyo({
          cart: data,
          addedIds: Array.isArray(input) ? input : [input],
          source,
        });

        return data;
      },
      [fn, lastPage, mutate, setLastProduct, source]
    );
  };

  useAddItem.extend = extendHook;

  return useAddItem;
}

export default extendHook(fetcher);
